import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Grid from '../components/grid';
import styled from "styled-components"
import tw from "tailwind.macro"

const Heading = styled.h1`
  ${tw`mb-2 font-black text-4xl leading-tight`};
`

const StyledLink = styled(Link)`
  ${tw`font-bold relative text-purple-darkest no-underline hover:text-red hover:underline`}
`

const Intro = styled.div`
  ${tw`text-xl mb-0 text-grey-darkest leading-normal`};
`

const Flex = styled.div`${tw`flex items-center mb-4`}`

const Time = styled.time`
  ${tw`block text-base font-mono text-grey-dark leading-normal`}
`

const Tag = styled(Link)`${tw`ml-2 font-normal no-underline text-grey-dark hover:text-red hover:underline`}`

const Body = styled.section`
  ${tw`text-xl mb-8`}
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
        <Layout location={this.props.location} >
            <article className="article mt-24">
                <Helmet title={`${post.title} | ${siteTitle}`} />
                <Helmet title={`${post.title} | ${siteTitle} | Web Developer in Stockholm`}>
                    <meta name="description" content={post.description.description} />
                </Helmet>
                <header className="article-header">
                    <Heading>
                        <StyledLink to={`/articles/${post.slug}`}>{post.title}</StyledLink>
                    </Heading>
                    <Flex>
                        <Time>{post.publishDate} in </Time>
                        <div>
                            {post.tags && post.tags.map(tag => (
                                <Tag to={`/tags/${tag}`} key={tag}>
                                    #{tag}
                                </Tag>
                            ))}
                        </div>
                    </Flex>

                    <Intro dangerouslySetInnerHTML={{ __html: post.description.childMarkdownRemark.html }} />
                </header>
                <Img className="mt-4 mb-8 rounded-lg article-image" alt={post.title} fluid={{...post.heroImage.fluid, aspectRatio: 12/9}} />

                <Body className="article-body" dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                }}>

                </Body>
            </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      tags
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      description {
          description
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
